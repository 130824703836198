import type { RootState } from '../../store';
import type { CoreStoreData } from './types';
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getProjects,
  getProjectData,
//  createProject,
} from '../../repositories/api/Core';

// const insertIdIfNotExists = (pool: string[] = [], id: string): string[] => [
//   ...pool,
//   ...(pool.includes(id) ? [] : [id]),
// ];

type Projects = Pick<'projects', CoreStoreData>;
export const getProjectsAction = createAsyncThunk<Projects, void, { state: RootState }>(
  'core/getProjects',
  (_, { getState }) => getProjects(getState().auth.accessToken)
    .then((projects) => projects.reduce((data, project) => ({
      ...data,
      [project.id]: {
        ...project,
      },
    }), {} as Projects)),
);

type ProjectData = {
  tickets: Pick<'tickets', CoreStoreData>;
  functionalities: Pick<'functionalities', CoreStoreData>;
};
export const getProjectDataAction = createAsyncThunk<ProjectData, string, { state: RootState }>(
  'core/getProject',
  (projectId: string, { getState }) => {
    const { accessToken } = getState().auth;

    return getProjectData(accessToken, projectId)
      .then(({ functionalities, tickets }) => ({
        functionalities: functionalities.reduce((temp, functionality) => ({
          ...temp,
          [functionality.id]: {
            ...functionality,
          },
        }), {} as Pick<'functionality', CoreStoreData>),
        tickets: tickets.reduce((temp, ticket) => ({
          ...temp,
          [ticket.id]: {
            ...ticket,
          },
        }), {} as Pick<'tickets', CoreStoreData>),
      }));
  },
);

// export const createProjectAction = createAsyncThunk<Project, void, { state: RootState }>(
//   'core/createProject',
//   (_, { getState }) => createProject(getState().auth.accessToken),
// );

// 
// export const getPointsAction = createAsyncThunk<PointsStoreData, string, { state: RootState }>(
//   'core/getPoints',
//   (projectId: string, { getState }) => {
//     const { accessToken } = getState().auth;
// 
//     return getPoints(accessToken, projectId)
//       .then((points) => points.reduce((data, point) => ({
//         projects: {
//           ...data.projects,
//           [point.projectId]: {
//             id: point.projectId,
//             license: point.license,
//             bridge: point.bridge,
//             date: point.projectDate,
//             sequenceIds: insertIdIfNotExists(data.projects[point.projectId]?.sequenceIds, point.sequenceId),
//             segmentIds: insertIdIfNotExists(data.projects[point.projectId]?.segmentIds, point.segmentId),
//             pointIds: insertIdIfNotExists(data.projects[point.projectId]?.pointIds, point.id),
//           },
//         },
//         sequences: {
//           ...data.sequences,
//           [point.sequenceId]: {
//             id: point.sequenceId,
//             name: point.sequenceName,
//             date: point.sequenceDate,
//             segmentIds: insertIdIfNotExists(data.sequences[point.sequenceId]?.segmentIds, point.segmentId),
//             pointIds: insertIdIfNotExists(data.sequences[point.sequenceId]?.pointIds, point.id),
//           },
//         },
//         segments: {
//           ...data.segments,
//           [point.segmentId]: {
//             id: point.segmentId,
//             name: point.segmentName,
//             type: point.segmentType,
//             sequenceIds: point.sequenceId,
//             pointIds: insertIdIfNotExists(data.segments[point.segmentId]?.pointIds, point.id),
//           },
//         },
//         points: {
//           ...data.points,
//           [point.id]: {
//             id: point.id,
//             projectId: point.projectId,
//             sequenceId: point.sequenceId,
//             segmentId: point.segmentId,
//             name: point.name,
//             type: point.type,
//             x: point.x,
//             y: point.y,
//             z: point.z,
//             preX: point.preX,
//             preY: point.preY,
//             preZ: point.preZ,
//           },
//         },
//       }), {
//         projects: {},
//         sequences: {},
//         segments: {},
//         points: {},
//       } as PointsStoreData))
//   },
//   {
//     condition: (projectId, { getState }) => {
//       const { core } = getState();
// 
//       if (core.projects[projectId]?.pointIds.length) {
//         return false;
//       }
//     }
//   },
// );
