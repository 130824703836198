import type { FC } from 'react';
import styles from './styles.module.scss';

type LoaderProps = {
  title?: string;
};

const Loader: FC<LoaderProps> = ({
  title = 'Loading',
}) => {
  return (
    <div className={styles.loader}>
      <svg
        className={styles.spinner}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="currentcolor"
          strokeWidth="10"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1.18s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          />
        </circle>
      </svg>
    </div>
  );
};

export default Loader;
