import { useEffect, useRef } from 'react';

type useImpureProps = () => void;

export const useImpure = (callback: useImpureProps): void => {
  const hasRun = useRef(false);

  useEffect(() => {
    if(!hasRun.current) {
      hasRun.current = true
      callback();
    }
  }, [callback]);
};
