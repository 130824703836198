import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../hooks/store';
import { getProjectsAction } from '../../store/core';
import HelpfulGuide from '../../components/HelpfulGuide';
import { Wrapper, Loader, Listing } from '../../components/Common';
import SectionTitle from '../../components/SectionTitle';
import ProjectCard from '../../components/ProjectCard';

const Home = () => {
  // const [newProjectLoading, setNewProjectLoading] = useState<boolean>(false);

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projects, loading } = useSelector((state) => state.core);

  useEffect(() => {
    dispatch(getProjectsAction());
  }, [dispatch]);

  // const handleNewProject = () => {
  //   setNewProjectLoading(true);

  //   dispatch(createProjectAction())
  //     .unwrap()
  //     .then((project) => {
  //       setNewProjectLoading(false);
  //       navigate(`/p/${project.id}/${project.title}`);
  //     });
  // };

  const projectsArray = Object.values(projects);
  const title = 'Your projects'

  if (loading) {
    return <Loader />
  }

  return (
    <Wrapper>
      <section aria-label={title}>
        <SectionTitle title={title} />
        {Boolean(projectsArray.length) && (
          <Listing>
            {projectsArray.map((project) => (
              <ProjectCard key={project.id} {...project} />
            ))}
          </Listing>
        )}
        {!Boolean(projectsArray.length) && (
          <HelpfulGuide message="Create a project to get started" />
        )}
      </section>
    </Wrapper>
  );
};

export default Home;
