import { FC } from 'react';
import styles from './styles.module.scss';

type SectionTitleProps = {
  title: string;
  children?: React.ReactNode;
};

const SectionTitle: FC<SectionTitleProps> = ({
  title,
  children,
}) => {
  return (
    <div className={styles.header}>
      <h2 className={styles.title}>{title}</h2>

      {children}
    </div>
  );
};

export default SectionTitle;
