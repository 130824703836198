import { useSearchParams } from 'react-router-dom';
import { useImpure } from '../../hooks/utils';
import { useDispatch } from '../../hooks/store';
import { signin } from '../../store/auth';
import { Loader } from '../../components/Common';

const Login = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useImpure(() => {
    const authCode = searchParams.get('code');
    const redirectUrl = `${window.location.origin}/login`;

    if (!authCode) {
      const params = new URLSearchParams({
        client_id: '33op77l3ete928r9f10ft8fv99',
        redirect_uri: redirectUrl,
        response_type: 'code',
        scope: 'openid email',
      });

      window.location.href = `https://auth.luciole.io/login?${params.toString()}`;
    } else {
      dispatch(signin({
        code: authCode,
        redirectUrl
      }));
    }
  });

  return (
    <Loader title="Signing you in. Please wait" />
  );
};

export default Login;
