import type { RootState } from '../../store';
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getToken, clearToken, refreshToken } from '../../repositories/api/Auth';

type Tokens = {
  accessToken: string;
  user: {
    email: string;
    firstName: string;
    lastName: string;
  };
};

export const signin = createAsyncThunk<Tokens, { code: string, redirectUrl: string }, { state: RootState }>(
  'auth/signin',
  ({ code, redirectUrl }) => getToken(code, redirectUrl),
);

export const signout = createAsyncThunk<void, void>(
  'auth/signout',
  (_, { getState }) => {
    const { accessToken } = getState().auth;

    return clearToken(accessToken);
  },
);

export const refreshSignin = createAsyncThunk<Tokens, void, { state: RootState }>(
  'auth/refreshSignin',
  () => refreshToken(),
);
