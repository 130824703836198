import type { FC } from 'react';
import type { Project } from '../../store/core/types';
import { Link } from 'react-router-dom';
import { toDateString } from '../../utils/date';
import styles from './styles.module.scss';

type ProjectCardProps = Project;

const ProjectCard: FC<ProjectCardProps> = ({
  id,
  title,
  date,
}) => {
  return (
    <Link className={styles.projectCard} to={`/p/${id}/${title}`}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.details}>
        {toDateString(date)}
      </div>
    </Link>
  );
};

export default ProjectCard;
