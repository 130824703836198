import type { FC } from 'react';
import styles from './styles.module.scss';

type ListingProps = {
  children: React.ReactElement[];
};

const Listing: FC<ListingProps> = ({
  children,
}) => {
  return (
    <div className={styles.listing}>
      <ul className={styles.list}>
        {children.map((child) => (
          <li key={child.key} className={styles.item}>
            {child}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Listing;
