import type { FC } from 'react';
import styles from './styles.module.scss';

type WrapperProps = {
  children: React.ReactNode;
}

const Wrapper: FC<WrapperProps> = ({
  children,
}) => {
  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
};

export default Wrapper;
