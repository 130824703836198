const AuthRepository = {
  getToken(code: string, redirectUrl: string) {
    return fetch('/api/auth/token', {
      method: 'POST',
      body: JSON.stringify({
        code,
        redirectUrl,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject();
        }
      });
  },
  clearToken(accessToken: string) {
    return fetch('/api/auth/signout', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return;
        } else {
          return Promise.reject();
        }
      });
  },
  refreshToken() {
    return fetch('/api/auth/refresh')
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject();
        }
      });
  },
};

export const {
  getToken,
  clearToken,
  refreshToken,
} = AuthRepository;

export default AuthRepository;
