import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const Banner = () => {
  return (
    <section className={styles.banner}>
      <Link to="/">Banner</Link>
    </section>
  );
};

export default Banner;
