import { createSlice } from '@reduxjs/toolkit'
import { signin, signout, refreshSignin } from './actions';

type AuthState = {
  isLoading: boolean;
  profile: {
    firstName: string;
    lastName: string;
    email: string;
  },
  accessToken: string;
  isSignedIn: boolean;
};

const initialState: AuthState = {
  isLoading: true,
  profile: {
    firstName: '',
    lastName: '',
    email: '',
  },
  accessToken: '',
  isSignedIn: false,
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(signin.fulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.profile.email = payload.user.email;
        state.isLoading = false;
        state.isSignedIn = true;
      })
      .addCase(signin.rejected, (state, { payload }) => {
        // TODO: sign out
        state.isLoading = false;
      })
      .addCase(signout.fulfilled, (state, { payload }) => {
        state.isSignedIn = false;
        state.isLoading = false;
      })
      .addCase(signout.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(refreshSignin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(refreshSignin.fulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.profile.email = payload.user.email;
        state.isLoading = false;
        state.isSignedIn = true;
      })
      .addCase(refreshSignin.rejected, (state, { payload }) => {
        // TODO: sign out
        state.isLoading = false;
      });
  },
});

// Action creators are generated for each case reducer function
export {
  signin,
  signout,
  refreshSignin,
};
export default authReducer.reducer
