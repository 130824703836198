import type { CoreStoreData } from './types';
import { createSlice } from '@reduxjs/toolkit'
import {
  getProjectsAction,
  getProjectDataAction,
  // createProjectAction,
} from './actions';

type CoreState = CoreStoreData & {
  loading: boolean;
};

const initialState: CoreState = {
  loading: true,
  projects: {},
  tickets: {},
  functionalities: {},
};

const coreReducer = createSlice({
  name: 'core',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectsAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.projects = payload;
      })
      .addCase(getProjectDataAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectDataAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tickets = payload.tickets;
        state.functionalities = payload.functionalities;
      })
      // .addCase(createProjectAction.fulfilled, (state, { payload }) => {
      //   state.projects = {
      //     ...state.projects,
      //     [payload.id]: payload,
      //   };
      // });
  },
});

export {
  getProjectsAction,
  getProjectDataAction,
  // createProjectAction,
};
export default coreReducer.reducer
