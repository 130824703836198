import type { FC } from 'react';
import type { Functionality } from '../../store/core/types';
import styles from './styles.module.scss';

type FunctionalityCardProps = Functionality;

const FunctionalityCard: FC<FunctionalityCardProps> = ({
  id,
  title,
  description
}) => {
  return (
    <button className={styles.functionalityCard}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.details}>
        <span className={styles.description}>{description}</span>
      </div>
      <div className={styles.meta}>
        <div/>
        <div className={styles.success} />
      </div>
    </button>
  );
};

export default FunctionalityCard;
