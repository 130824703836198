import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { initFeatureFlags } from './flags';
import { refreshSignin } from './store/auth';
import MainLayout from './layouts/MainLayout';
import { PrivateRoute, PublicRoute } from './layouts/RequireAuth';
import Home from './routes/Home';
import Project from './routes/Project';
import Login from './routes/Login';
import store from './store'

initFeatureFlags();
store.dispatch(refreshSignin());

const App = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/p/:projectId/:projectName" element={<Project />} />
        </Route>
      </Route>
      <Route element={<PublicRoute />}>
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default App;
