import type { ProjectDto } from './types';

const CoreRepository = {
  getProjects(accessToken: string): Promise<ProjectDto[]> {
    return fetch('/api/project', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject();
        }
      })
      .then(({ projects }) => projects);
  },

  getProjectData(accessToken: string, projectId: string): Promise {
    return fetch(`/api/project/${projectId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject();
        }
      });
  },

  // createProject(accessToken: string): Promise<Project> {
  //   return fetch(`/api/project`, {
  //     method: 'POST',
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         return Promise.reject();
  //       }
  //     });
  // },
};

export const {
  getProjects,
  getProjectData,
  // createProject,
} = CoreRepository;

export default CoreRepository;
