// import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// import { useDispatch, useSelector } from '../../hooks/store';
// import { getProjectsAction } from '../../store/core';
// import { Loader } from '../../components/Common';
import Banner from '../../components/Banner';

const MainLayout = () => {
  return (
    <div>
      <nav>
        <Banner />
      </nav>

      <Outlet />
    </div>
  );
};

export default MainLayout;
