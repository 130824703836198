import type { RootState } from '../../store';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from '../../hooks/store';
import { Loader } from '../../components/Common';

export const PrivateRoute = () => {
  const { isSignedIn, isLoading } = useSelector((state) => state.auth);
  const location = useLocation();

  if (isLoading) {
    return <Loader />;
  }

  return !isSignedIn
    ? <Navigate to="/login" state={{ from: location }} replace />
    : <Outlet />;
};

export const PublicRoute = () => {
  const { isSignedIn } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  return isSignedIn
    ? <Navigate to="/" state={{ from: location }} replace />
    : <Outlet />;
};
