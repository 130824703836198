import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from '../../hooks/store';
import { getProjectDataAction } from '../../store/core';
import { Listing } from '../../components/Common';
import FunctionalityCard from '../../components/FunctionalityCard';
// import { Select, Loader } from '../../components/Common';

const Project = () => {
  const dispatch = useDispatch();
  const { functionalities } = useSelector((state) => state.core);
  const { projectId, projectName } = useParams();

  useEffect(() => {
    dispatch(getProjectDataAction(projectId as string));
  }, [dispatch, projectId]);

  const functionaliesArray = Object.values(functionalities);

  return (
    <>
      Project: {projectId} - {projectName}
      <Listing>
        {functionaliesArray.map((functionality) => (
          <FunctionalityCard key={functionality.id} {...functionality} />
        ))}
      </Listing>
    </>
  );
};

export default Project;
